import React from 'react'
import styled from 'styled-components'
import SplitScreen from 'components/SplitScreen'
import Heading from 'components/Heading'
import Markdown from 'components/styled/Markdown'

const Text = styled(Markdown)`
  p,
  strong {
    color: white;
  }
`
export default ({ title, src, color, content }) => (
  <SplitScreen src={src} color={color}>
    <Heading>{title}</Heading>
    <Text children={content} />
  </SplitScreen>
)
