import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import OverlayLeft from 'components/OverlayLeft'
import Heading from 'components/Heading'
import Paragraph from 'components/styled/Paragraph'

const Tuition = ({ title, src, color, link, content }) => (
  <OverlayLeft src={src}>
    <Heading color={color}>{title}</Heading>
    <Description>
      {content}
      <Button
        color={color}
        href={link}
        target="_blank"
        rel="noopener noreferrer"
      >
        VIEW PRICING
      </Button>
    </Description>
  </OverlayLeft>
)

Tuition.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  color: PropTypes.oneOf(['lightBlue', 'purple', 'pink', 'green']),
  link: PropTypes.string.isRequired,
}

Tuition.defaultProps = {
  color: 'purple',
}

export default Tuition

const Description = styled(Paragraph)`
  text-align: left;
`
const Button = styled.a`
  display: block;
  margin: 20px auto;
  padding: 20px 35px;
  color: white;
  background-color: ${(props) => props.theme[props.color || 'purple']};
  font-family: ${(props) => props.theme.bodyFont};
  line-height: 0;
  letter-spacing: 1px;
  font-size: 14pt;
  font-weight: 700;
  border-radius: 20px;
  border: 2px solid ${(props) => props.theme[props.color || 'purple']};
  text-align: center;
  width: 220px;
  transition: 0.5s;

  &:hover {
    border: 2px solid ${(props) => props.theme[props.color || 'purple']};
    background-color: white;
    color: ${(props) => props.theme[props.color || 'purple']};
  }
`
