import React, { Fragment } from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'

import Seo from 'components/SEO'
import Header from 'components/HeaderSection'
import Curriculum from 'components/Curriculum'
import Tuition from 'components/Tuition'

export default ({ data }) => {
  const { frontmatter } = data.markdownRemark
  const { seo, header, curriculum, tuition, extra } = frontmatter
  return (
    <Fragment>
      <Seo title={seo.title} description={seo.description} />
      <Helmet title={seo.title} />
      <Header
        title={header.title}
        color="lightBlue"
        src={header.image.childImageSharp.gatsbyImageData.images.fallback.src}
        content={header.description}
      />
      <Curriculum
        title={curriculum.title}
        src={
          curriculum.image.childImageSharp.gatsbyImageData.images.fallback.src
        }
        content={curriculum.description}
        color="lightBlue"
      />
      <Tuition
        title={tuition.title}
        src={tuition.image.childImageSharp.gatsbyImageData.images.fallback.src}
        color="purple"
        link={tuition.pricingSheet.publicURL}
        content={tuition.description}
      />
    </Fragment>
  )
}

export const query = graphql`
  query ToddlersPageQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        seo {
          title
          description
        }
        header {
          title
          description
          image {
            childImageSharp {
              gatsbyImageData(sizes: "2200")
            }
          }
        }
        curriculum {
          title
          description
          image {
            childImageSharp {
              gatsbyImageData(
                transformOptions: { cropFocus: ATTENTION }
                height: 400
              )
            }
          }
        }
        tuition {
          title
          description
          pricingSheet {
            publicURL
          }
          image {
            childImageSharp {
              gatsbyImageData(
                transformOptions: { cropFocus: NORTH }
                height: 400
              )
            }
          }
        }
        extra {
          title
          backgroundImage {
            childImageSharp {
              gatsbyImageData
            }
          }
          activities {
            title
            description
            included
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`
